import "survey-core/defaultV2.min.css";

import { auth } from "../firebase";

import {
  kalkulal_alap,
  kalkulal_tagok_szama,
  kalkulal_baleseti,
  kalkulal_szerzodes_szam,
  kalkulal_kieg,
  kalkulal_kieg_kedvezmeny,
  kalkulal_kedvezmeny,
} from "./utils/KalkulatorUtils";

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function formatKalkulatorNumber(x) {
  var xx = x;
  // console.log(x, typeof x);
  if (x.toString().slice(-2) !== "Ft") {
    xx = x + " Ft";
  }
  // console.log(xx);
  // console.log(">>", numberWithCommas(xx).padStart(10, " "), "<<");
  return numberWithCommas(xx).padStart(10, " ");
}

function Kalkulator(props) {
  const surveyDataEntries = Object.entries(props.values);
  const kalk_data = props.values;

  // console.log("K", props);
  // console.log("KK", props.values_only);

  // var kalk_alap = 0;

  if (surveyDataEntries) {
    var kalk_alap = kalkulal_alap(
      kalk_data["flotta_tipus"],
      kalk_data["flotta_csomag"],
      kalk_data["flotta_szerzodes_szam"],
      kalk_data["flotta_egyedi"]
    );
    var kalk_tagok_szama = kalkulal_tagok_szama(
      kalk_data["flotta_tipus"],
      kalk_data["flotta_tagok"]
    );
    var kalk_baleseti = kalkulal_baleseti(
      kalk_data["flotta_tipus"],
      kalk_data["flotta_tagok"],
      kalk_data["kiegeszito_szolgaltatasok"],
      kalk_data["ugyfel_kezelo_baleseti"]
    );
    var kalk_kieg = kalkulal_kieg(
      kalk_data["flotta_csomag"],
      kalk_data["flotta_tipus"],
      kalk_data["kiegeszito_szolgaltatasok"],
      kalk_data["flotta_szerzodes_szam"],
      kalk_data["karugyintezes_korlatlan"],
      kalk_data["karugyintezes"]
    );
    var kalk_kieg_kedv = kalkulal_kieg_kedvezmeny(
      kalk_data["flotta_csomag"],
      kalk_data["flotta_tipus"],
      kalk_data["kiegeszito_szolgaltatasok"]
    );

    var kalk_szerzodes_szam = kalkulal_szerzodes_szam(
      kalk_data["flotta_csomag"],
      kalk_data["flotta_tipus"],
      kalk_data["flotta_szerzodes_szam"]
    );

    var szumma1 =
      kalk_alap +
      kalk_tagok_szama +
      kalk_kieg -
      kalk_kieg_kedv +
      kalk_szerzodes_szam;
    if (kalk_data["flotta_egyedi_ar"] > 0) {
      kalk_alap = kalk_data["flotta_egyedi_ar"];
      kalk_tagok_szama = 0;
      kalk_kieg = 0;
      kalk_kieg_kedv = 0;
      kalk_szerzodes_szam = 0;
      szumma1 = kalk_data["flotta_egyedi_ar"];
    }
    // console.log(
    //   kalk_alap,
    //   kalk_tagok_szama,
    //   kalk_kieg,
    //   kalk_kieg_kedv,
    //   kalk_szerzodes_szam
    // );

    var kalk_kedvezmeny = kalkulal_kedvezmeny(
      szumma1,
      kalk_data["flotta_kedvezmeny_tipus"],
      kalk_data["flotta_kedvezmeny_mertek"],
      kalk_data["flotta_akciok"]
    );
    var szumma =
      kalk_kedvezmeny >= 0
        ? Math.round(kalk_kedvezmeny) + kalk_baleseti
        : kalk_kedvezmeny;

    var _kedvezmeny = Math.max(szumma1 - kalk_kedvezmeny, 0);
    _kedvezmeny += kalk_kieg_kedv;

    if (props.values_only) {
      return [szumma, _kedvezmeny];
    }
  }

  var dijKorrekcio = "";
  if (kalk_data["flotta_kedvezmeny_tipus"] !== "nincs") {
    if (kalk_data["flotta_kedvezmeny_mertek"]) {
      if (kalk_data["flotta_kedvezmeny_mertek"].toString()[0] === "-") {
        dijKorrekcio =
          "+" +
          numberWithCommas(
            Math.abs(kalk_data["flotta_kedvezmeny_mertek"])
          ).padStart(10, " ");
      } else {
        dijKorrekcio =
          "-" +
          numberWithCommas(kalk_data["flotta_kedvezmeny_mertek"]).padStart(
            10,
            " "
          );
      }
    }
    if (kalk_data["flotta_kedvezmeny_tipus"].toString().slice(-1) !== "%") {
      dijKorrekcio += " Ft";
    } else {
      dijKorrekcio += " %";
    }
  } else {
    dijKorrekcio += "-0 Ft";
  }

  return (
    <div class="grid grid-cols-6 gap-1 overflow-auto pl-4 pt-4">
      <div class="col-start-1 font-semibold">
        <h4>Kalkuláció:</h4>
      </div>

      <div class="col-start-1 col-span-3">Alap:</div>
      <div class="col-start-4 col-span-2 text-right">
        {formatKalkulatorNumber(kalk_alap)}
      </div>

      <div class="col-start-1 col-span-3">Szerzodes száma:</div>
      <div class="col-start-4 col-span-2 text-right">
        {formatKalkulatorNumber(kalk_szerzodes_szam)}
      </div>

      <div class="col-start-1 col-span-3">Tagok száma:</div>
      <div class="col-start-4 col-span-2 text-right">
        {formatKalkulatorNumber(kalk_tagok_szama)}
      </div>

      <div class="col-start-1 col-span-3">Kiegészítő szolg:</div>
      <div class="col-start-4 col-span-2 text-right">
        {formatKalkulatorNumber(kalk_kieg)}
      </div>

      <div class="col-start-1 col-span-3">Kiegek száma kedv:</div>
      <div class="col-start-4 col-span-2 text-right">
        {formatKalkulatorNumber("-" + kalk_kieg_kedv)}
      </div>

      <div class="col-start-1 col-span-3"></div>
      <div class="col-start-4 col-span-2 text-right">=</div>

      <div class="col-start-1 col-span-3">_Szumma:</div>
      <div class="col-start-4 col-span-2 text-right">
        {formatKalkulatorNumber(szumma1)}
      </div>

      <div class="col-start-1 col-span-3">Díjkorrekció:</div>
      <div class="col-start-4 col-span-2 text-right">{dijKorrekcio}</div>

      <div class="col-start-1 col-span-3"></div>
      <div class="col-start-4 col-span-2 text-right">=</div>

      <div class="col-start-1 col-span-3"></div>
      <div class="col-start-4 col-span-2 text-right">
        {formatKalkulatorNumber(kalk_kedvezmeny)}
      </div>

      <div class="col-start-1 col-span-3"></div>
      <div class="col-start-4 col-span-2 text-right">+</div>

      <div class="col-start-1 col-span-3">Baleseti(ük&ü):</div>
      <div class="col-start-4 col-span-2 text-right">
        {formatKalkulatorNumber(kalk_baleseti)}
      </div>

      <div class="col-start-1 col-span-3"></div>
      <div class="col-start-4 col-span-2 text-right">=</div>

      <div class="col-start-1 col-span-3 font-semibold">
        Fizetendő éves díj:
      </div>
      <div class="col-start-4 col-span-2 text-right font-semibold">
        {formatKalkulatorNumber(szumma)}
      </div>
    </div>

    //     {/* <div className="flex-row w-full">
    //       <h4>{"-".repeat(40)}</h4>
    //       <h4>Data:</h4>
    //       <ul className="w-full">
    //         {surveyDataEntries.map(([key, value]) => {
    //           return (
    //             <li key={`${key}`}>
    //               {key} = {JSON.stringify(value)}
    //             </li>
    //           );
    //         })}
    //       </ul>
    //     </div> */}
    //   </div>
    // </aside>
  );
}

export default Kalkulator;
