import axios from "axios";

function renameKeys(obj, newKeys) {
  const keyValues = Object.keys(obj).map((key) => {
    const newKey = newKeys[key] || key.replace("fm_", "");
    return { [newKey]: obj[key] };
  });
  return Object.assign({}, ...keyValues);
}

const szotar = [
  { key: "Passzív", value: "passziv" },
  { key: "Standard", value: "standard" },
  { key: "All In", value: "all_in" },
  { key: "Egyedi", value: "egyedi" },
  { key: "magánszemély", value: "magan" },
  { key: "egyéni vállalkozás", value: "egyeni" },
  { key: "mikrocég vagy egyéb intézmény", value: "mikro_egyeb" },
  { key: "cég", value: "ceg" },

  { key: "Kiemelt", value: "kiemelt" },
  { key: "Extra", value: "extra" },
  { key: "Max", value: "max" },
  { key: "Kiemelt-Családi", value: "kiemelt_csaladi" },
  { key: "Extra-Családi", value: "extra_csaladi" },
  { key: "Max-Családi", value: "max_csaladi" },

  { key: "Teljeskörű képviselet", value: "egyedi-item1" },
  {
    key: "Személyes jelenlét nélküli ügyintézés és biztosításkötés",
    value: "egyedi-item2",
  },
  { key: "Díjmentes általános biztosítási ügyintézés", value: "egyedi-item3" },
  {
    key: "Közvetlen kapcsolat kereskedéssel/hitelezővel",
    value: "egyedi-item4",
  },
  { key: "Biztosítási virtuális asszisztencia", value: "egyedi-item5" },
  {
    key: "Rendszeres díjfigyelés és elmaradásértesítés",
    value: "egyedi-item6",
  },
  { key: "Kárbejelentés és tanácsadás", value: "egyedi-item8" },
  {
    key: "50%-os kedvezmény teljeskörű kárügyintézésre – kármenedzser",
    value: "egyedi-item9",
  },
  { key: "Igazolások rendszeres kiadása", value: "egyedi-item10" },
  {
    key: "Automatikus évfordulós felülvizsgálat – versenyeztetés",
    value: "egyedi-item11",
  },
  {
    key: "Kedvezményes emelt szintű balesetbiztosítási opció",
    value: "egyedi-item12",
  },
  { key: "Konzultációs és ajánlatkérési lehetőség", value: "egyedi-item13" },
  { key: "Irodán kívüli ügyintézés", value: "egyedi-item14" },
  { key: "+/-", value: "-" },
  { key: "+/- %", value: "-%" },
  { key: "Fuvarozó", value: "item1" },
  { key: "Futár", value: "item2" },
  { key: "Ipari gyártás", value: "item3" },
  { key: "Nemzetközi tevékenység", value: "item4" },
  { key: "Közéleti/Nyilvános tevékenység", value: "item5" },
];

async function LoadKalkulatorDataFromCRM(flottaId) {
  let baseData = {};
  await axios({
    method: "post",
    url: "https://antabo-api.onrender.com/flottamanager",
    // url: "http://localhost:8000/flottamanager",
    data: { flotta_id: `${flottaId}` },
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "https://antabo-api.onrender.com",
      // "Access-Control-Allow-Origin": "http://localhost:8000",
    },
    withCredentials: true,
    responseEncoding: "utf8",
  }).then(function (res) {
    console.log(res.data);
    baseData = JSON.parse(res.data);
  });

  // console.log("PreRENAME", typeof baseData);

  baseData["kalkulator_tipusa"] = "modositas";

  const newKeys = {
    c_nev: "flotta_nev",
    sz_adoszam: "flotta_adoszam",
    sz_szul_ido: "flotta_szul_ido",
    fm_varhato_szerzodesszam: "flotta_szerzodes_szam",
    fm_dijkorrekcio_tipusa: "flotta_kedvezmeny_tipus",
    fm_dijkorrekcio_merteke: "flotta_kedvezmeny_mertek",
    fm_egyedi_ar: "flotta_egyedi_ar",
    fm_tulaj_aktiv_a_cegben: "tulaj_aktiv_a_cegben",
    fm_egyeb_szolgaltatasok: "egyeb_szolgaltatasok",
    fm_egyedi_szolgaltatasok: "egyedi_szolgaltatasok",
    fm_egyedi_megallapodas: "egyedi_megallapodas",
    fm_kar_db: "karugyintezes",
    ugyfel_kezelo_baleseti_csomag: "ugyfel_kezelo_baleseti",
    fm_egyedi_konzultacio: "flotta_konzultacio",
    fm_egyedi_irodankivuli: "flotta_ugyintezes",
    fm_eves_dij: "flotta_egyedi_ar",
  };

  const rData = renameKeys(baseData, newKeys);

  // console.log(baseData);
  // console.log(baseData["fm_kar"]);
  rData["kiegeszito_szolgaltatasok"] = [];

  if (baseData["fm_kar"] === "Igen") {
    rData["kiegeszito_szolgaltatasok"].push("kieg_kar");
  }

  if (baseData["fm_posta"] === "Igen") {
    rData["kiegeszito_szolgaltatasok"].push("posta");
  }

  if (baseData["fm_teendo"] === "Igen") {
    rData["kiegeszito_szolgaltatasok"].push("teendo");
  }

  if (baseData["fm_emelt_baleset_uk"] === "Igen") {
    rData["kiegeszito_szolgaltatasok"].push("emelt_baleset_uk");
  }

  if (baseData["fm_vezetoi_engedely"] === "Vezetői engedélly OK") {
    rData["flotta_vezetoi_engedely"] = "igen";
  }

  rData["egyeb_szolgaltatasok"] = {};

  if (baseData["fm_egyeb_szolgaltatasok_1"]) {
    rData["egyeb_szolgaltatasok"]["Egyéb szolgáltatás (1)"] =
      baseData["fm_egyeb_szolgaltatasok_1"];
  }
  if (baseData["fm_egyeb_szolgaltatasok_2"]) {
    rData["egyeb_szolgaltatasok"]["Egyéb szolgáltatás (2)"] =
      baseData["fm_egyeb_szolgaltatasok_2"];
  }
  if (baseData["fm_egyeb_szolgaltatasok_3"]) {
    rData["egyeb_szolgaltatasok"]["Egyéb szolgáltatás (3)"] =
      baseData["fm_egyeb_szolgaltatasok_3"];
  }

  // var kizaro_tevekenyseg = [
  //   ''
  // ]

  // rData["tevekenyseg"] = []

  // for (const element of kizaro_tevekenyseg) {

  //   //   if (baseData["fm_egyeb_szolgaltatasok_1"]) {
  //   //     rData["egyeb_szolgaltatasok"]["Egyéb szolgáltatás (1)"] = baseData["fm_egyeb_szolgaltatasok_1"]
  //   //   }
  //   //   if (baseData["fm_egyeb_szolgaltatasok_2"]) {
  //   //     rData["egyeb_szolgaltatasok"]["Egyéb szolgáltatás (2)"] = baseData["fm_egyeb_szolgaltatasok_2"]
  //   //   }
  //   //   if (baseData["fm_egyeb_szolgaltatasok_3"]) {
  //   //     rData["egyeb_szolgaltatasok"]["Egyéb szolgáltatás (3)"] = baseData["fm_egyeb_szolgaltatasok_3"]
  // }

  //   tevekenyseg

  // console.log(rData["flotta_tagok"]);

  // var int_data = [
  //   "arbevetel",
  //   "alkamazott_szam",
  // ]

  var szotarando = [
    "flotta_csomag",
    "flotta_tipus",
    ["egyedi_szolgaltatasok"],
    "flotta_kedvezmeny_tipus",
    "ugyfel_kezelo_baleseti",
    ["tevekenyseg"],
  ];

  for (const element of szotarando) {
    if (typeof element === "object") {
      // console.log(element);
      let retArray = [];
      for (const elem of rData[element].split(", ")) {
        szotar.find((obj) => {
          if (obj.key === elem) {
            retArray.push(obj.value);
          }
          return 0;
        });
        rData[element] = retArray;
      }
    } else {
      // console.log(element);
      szotar.find((obj) => {
        if (obj.key === rData[element]) {
          rData[element] = obj.value;
        }
        return 0;
      });
    }
  }

  if (Object.keys(rData).indexOf("flotta_tagok") > 0) {
    for (let [index, tag] of rData["flotta_tagok"].entries()) {
      console.log(index, tag);

      szotar.find((obj) => {
        if (obj.key === rData["flotta_tagok"][index]["flotta_tag_tipus"]) {
          rData["flotta_tagok"][index]["flotta_tag_tipus"] = obj.value;
        }
        if (obj.key === rData["flotta_tagok"][index]["flotta_tag_csomag"]) {
          rData["flotta_tagok"][index]["flotta_tag_csomag"] = obj.value;
        }
        console.log(rData["flotta_tagok"][index]["flotta_tag_csomag"]);
        return 0;
      });
    }
  }

  // console.log("RETURN", rData);

  return rData;
}

export default LoadKalkulatorDataFromCRM;
