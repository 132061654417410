const kalkulal_mikro = {
  "0-2": 20000,
  "3-5": 30000,
  "6-8": 40000,
  "9-12": 50000,
  max: 50000,
};

const baleseti_csomagok = {
  nem: 0,
  alap: 0,
  kiemelt: 3000,
  extra: 6000,
  max: 12000,
  kiemelt_csaladi: 6000,
  extra_csaladi: 12000,
  max_csaladi: 24000,
};

const kieg_arak = {
  magan: {
    kar_2db: 5990,
    kar_korlatlan: 10990,
    teendo: 1990,
    posta: 4990,
  },
  egyeni: {
    kar_2db: 5990,
    kar_korlatlan: 10990,
    teendo: 1990,
    posta: 4990,
  },
  mikro_1: {
    kar_2db: 6000,
    kar_korlatlan: 11000,
    teendo: 2000,
    posta: 5000,
  },
  mikro_2: {
    kar_2db: 9000,
    kar_korlatlan: 19000,
    teendo: 4000,
    posta: 7000,
  },
};

export const kalkulal_alap = (
  flotta_tipus,
  flotta_csomag,
  flotta_szerzodes_szam,
  flotta_egyedi
) => {
  var ret = 0;
  if (flotta_csomag === "standard") {
    if (flotta_tipus === "magan") {
      ret = 9990;
    } else if (flotta_tipus === "egyeni") {
      ret = 14990;
    } else if (flotta_tipus === "mikro_egyeb") {
      // ret = 20000;
      for (let key in kalkulal_mikro) {
        var range = key.split("-");
        if (
          parseInt(range[0]) <= flotta_szerzodes_szam &&
          flotta_szerzodes_szam <= parseInt(range[1])
        ) {
          ret = kalkulal_mikro[key];
        }
      }
      if (ret === 0) {
        ret = kalkulal_mikro["max"];
      }
    }
  }
  return ret;
};

export const kalkulal_tagok_szama = (flotta_tipus, flotta_tagok) => {
  var ret = 0;

  if (flotta_tipus === "magan") {
    ret = 2000;
  } else if (flotta_tipus === "egyeni") {
    ret = 5000;
  } else if (flotta_tipus === "mikro_egyeb") {
    ret = 10000;
  }
  if (flotta_tagok) {
    ret = ret * flotta_tagok.length;
    return ret;
  } else {
    return 0;
  }
};

export const kalkulal_baleseti = (
  flotta_tipus,
  flotta_tagok,
  kiegeszito_szolgaltatasok,
  ugyfel_kezelo_baleseti
) => {
  var ret = 0;
  if (ugyfel_kezelo_baleseti && kiegeszito_szolgaltatasok && flotta_tipus) {
    if (
      (flotta_tipus === "magan" || flotta_tipus === "egyeni") &&
      kiegeszito_szolgaltatasok.includes("emelt_baleset_uk") &&
      ugyfel_kezelo_baleseti
    ) {
      ret += baleseti_csomagok[ugyfel_kezelo_baleseti];
    }
  }

  if (flotta_tagok) {
    flotta_tagok.forEach((tag) => {
      if (tag["flotta_tag_csomag"]) {
        ret += baleseti_csomagok[tag["flotta_tag_csomag"]];
      }
    });
  }

  return ret;
};

export const kalkulal_szerzodes_szam = (
  flotta_csomag,
  flotta_tipus,
  flotta_szerzodes_szam
) => {
  var ret = 0;
  if (flotta_csomag === "standard") {
    if (flotta_tipus === "magan") {
      ret = 1000;
    } else if (flotta_tipus === "egyeni") {
      ret = 1500;
    }
    if (parseInt(flotta_szerzodes_szam) > 5) {
      ret = ret * (parseInt(flotta_szerzodes_szam) - 5);
      return ret;
    } else {
      return 0;
    }
  } else {
    return 0;
  }
};

export const kalkulal_kieg = (
  flotta_csomag,
  flotta_tipus,
  kiegeszito_szolgaltatasok,
  flotta_szerzodes_szam,
  karugyintezes_korlatlan,
  karugyintezes
) => {
  // console.log(flotta_tipus, kiegeszito_szolgaltatasok, flotta_szerzodes_szam);
  if (flotta_csomag !== "standard") {
    return 0;
  }

  var ret = 0;
  var kieg_name = "";

  if (kiegeszito_szolgaltatasok) {
    if (flotta_tipus === "magan" || flotta_tipus === "egyeni") {
      kieg_name = flotta_tipus;
    } else if (flotta_tipus === "mikro_egyeb" && flotta_szerzodes_szam) {
      if (parseInt(flotta_szerzodes_szam) <= 5) {
        kieg_name = "mikro_1";
      } else {
        kieg_name = "mikro_2";
      }
    } else {
      return 0;
    }

    if (kieg_arak[kieg_name]) {
      kiegeszito_szolgaltatasok.forEach((element) => {
        if (kieg_arak[kieg_name][element]) {
          ret += kieg_arak[kieg_name][element];
        }
      });
      if (karugyintezes_korlatlan === "igen") {
        ret += kieg_arak[kieg_name]["kar_korlatlan"];
      }
      if ((karugyintezes_korlatlan === "nem") & (karugyintezes === "2")) {
        ret += kieg_arak[kieg_name]["kar_2db"];
      }
    }
  }

  return ret;
};

export const kalkulal_kieg_kedvezmeny = (
  flotta_csomag,
  flotta_tipus,
  kiegeszito_szolgaltatasok
) => {
  if (flotta_csomag !== "standard") {
    return 0;
  }
  var ret = 0;
  if (
    kiegeszito_szolgaltatasok &&
    (flotta_tipus === "magan" || flotta_tipus === "egyeni")
  ) {
    if (parseInt(kiegeszito_szolgaltatasok.length) >= 2) {
      ret += (parseInt(kiegeszito_szolgaltatasok.length) - 1) * 1000;
    }
  }

  return ret;
};

export const kalkulal_kedvezmeny = (
  szumma = 0,
  flotta_kedvezmeny_tipus,
  flotta_kedvezmeny_mertek = 0,
  flotta_akciok = []
) => {
  // console.log(szumma, flotta_kedvezmeny_tipus, flotta_kedvezmeny_mertek);
  var ret = parseInt(szumma);

  if (flotta_akciok.length > 0) {
    if (flotta_akciok.includes("Lojalitás")) {
      ret = ret * (1 - 0.25);
    }
    if (flotta_akciok.includes("Hűség")) {
      ret = ret * (1 - 0.35);
    }
  }

  if (flotta_kedvezmeny_tipus && flotta_kedvezmeny_mertek) {
    // console.log(ret, szumma);
    if (flotta_kedvezmeny_tipus === "-") {
      ret = ret - parseInt(flotta_kedvezmeny_mertek);
    } else {
      ret = (ret * (100 - parseFloat(flotta_kedvezmeny_mertek))) / 100;
    }
  }

  if (ret < 0) {
    return "Nem lehet negatív a kedvezménnyel számolt díj!";
  } else {
    return ret;
  }
};
