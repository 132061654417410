import React from "react";
import axios from "axios";

const getFlottaManagerPDF = ({ data }) => {
  console.log(data);
  // var karugyintezes = "";
  // if (data.karugyintezes_korlatlan === "igen") {
  //   karugyintezes = "kar_korlatlan";
  // }
  // if ((data.karugyintezes_korlatlan === "nem") & (data.karugyintezes === 2)) {
  //   karugyintezes = "kar_2db";
  // }

  // var kiegeszito_szolgaltatasok = data.kiegeszito_szolgaltatasok || [];

  // // if () {
  // //   kiegeszito_szolgaltatasok.append("kieg_kor")
  // // }

  // return;
  axios({
    method: "post",
    url: `https://dokugeneralo.onrender.com/flotta-manager-megallapodas`,
    // url: `http://localhost:8000/flotta-manager-megallapodas`,
    data: {
      flotta_nev: data.flotta_nev || "",
      flotta_id: data.flotta_id || "",
      flotta_tagok: JSON.stringify(data.flotta_tagok) || "{}",
      flotta_szerzodes_szam: data.flotta_szerzodes_szam || "",
      flotta_email: data.flotta_email || "",
      flotta_csomag: data.flotta_csomag || "",
      flotta_tipus: data.flotta_tipus || "",
      flotta_szul_ido: data.flotta_szul_ido || "",
      flotta_adoszam: data.flotta_adoszam || "",
      kiegeszito_szolgaltatasok: data.kiegeszito_szolgaltatasok || [],
      ugyfel_kezelo_baleseti: data.ugyfel_kezelo_baleseti || "",
      egyedi_megallapodas: data.egyedi_megallapodas || "",
      karugyintezes_korlatlan: data.karugyintezes_korlatlan || "",
      karugyintezes: data.karugyintezes || "",
      kitoltes_ideje: data.kitoltes_ideje || "",
      flotta_idoszak_kezdete: data.flotta_idoszak_kezdete || "",
      flotta_idoszak_vege: data.flotta_idoszak_vege || "",
      flotta_dij: data.flotta_dij || "",
      flotta_ossz_kedvezmeny: data.flotta_ossz_kedvezmeny || "",
      flotta_akcio: data.flotta_akciok || [],
      flotta_kupon: data.flotta_kupon || "",
    },
    responseType: "blob",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `https://dokugeneralo.onrender.com`,
    },
    withCredentials: true,
  })
    .then(function (response) {
      //   console.log(response);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `flotta-manager-megallapodas_${data.flotta_nev}.pdf`
      );
      link.click();
    })
    .catch(function (response) {
      console.log(response.message);
    });
};

const uploadToMiniCRM = ({ data }) => {
  document.getElementById("result").innerHTML = "Loading...";
  document.getElementById("json").innerHTML = "Loading...";
  console.log(data);
  // return;
  axios({
    method: "post",
    url: `https://antabo-api.onrender.com/create_flottamanager`,
    // url: `http://localhost:8000/create_flottamanager`,
    data: {
      flotta_nev: data.flotta_nev || "",
      flotta_tagok: JSON.stringify(data.flotta_tagok) || "{}",
      flotta_szerzodes_szam: data.flotta_szerzodes_szam || "",
      flotta_email: data.flotta_email || "",
      flotta_csomag: data.flotta_csomag || "",
      flotta_tipus: data.flotta_tipus || "",
      flotta_arbevetel: data.arbevetel || "",
      flotta_alkamazott_szam: data.alkamazott_szam || "",
      flotta_tevekenyseg: data.tevekenyseg || [],
      flotta_tulaj_aktiv_a_cegben: data.tulaj_aktiv_a_cegben || [],
      flotta_szul_ido: data.flotta_szul_ido || "",
      flotta_adoszam: data.flotta_adoszam || "",
      kiegeszito_szolgaltatasok: data.kiegeszito_szolgaltatasok || [],
      ugyfel_kezelo_baleseti: data.ugyfel_kezelo_baleseti || "",
      egyedi_megallapodas: data.egyedi_megallapodas || "",
      karugyintezes: data.karugyintezes || "",
      kitoltes_ideje: data.kitoltes_ideje || "",
      flotta_idoszak_kezdete: data.flotta_idoszak_kezdete || "",
      flotta_idoszak_vege: data.flotta_idoszak_vege || "",
      flotta_dij: data.flotta_dij || "",
      flotta_ossz_kedvezmeny: data.flotta_ossz_kedvezmeny || "",
      flotta_akcio: data.flotta_akciok || [],
      flotta_kupon: data.flotta_kupon || "",
      flotta_telefon: data.flotta_telefon || "",
      flotta_kedvezmeny_mertek: data.flotta_kedvezmeny_mertek || "",
      flotta_kedvezmeny_tipus: data.flotta_kedvezmeny_tipus || "",
      flotta_vezetoi_engedely: data.flotta_vezetoi_engedely || [],

      flotta_manager_id: data.flotta_manager_id || "0",
      flotta_id: data.flotta_id || "0",
      ugyfel_kezelo_contact_id: data.ugyfel_kezelo_contact_id || "0",
      ugyfel_kezelo_id: data.ugyfel_kezelo_id || "0",
      ugyfel_kezelo_baleseti_id: data.ugyfel_kezelo_baleseti_id || "0",

      egyedi_szolgaltatasok: data.egyedi_szolgaltatasok || [],
      egyeb_szolgaltatasok: data.egyeb_szolgaltatasok || "",
      flotta_konzultacio: data.flotta_konzultacio || "",
      flotta_ugyintezes: data.flotta_ugyintezes || "",
      flotta_egyedi_ar: data.flotta_egyedi_ar || "",
    },
    responseType: "application/json",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `https://antabo-api.onrender.com`,
      // "Access-Control-Allow-Origin": `http://localhost:8000`,
    },
    withCredentials: true,
  })
    .then(function (response) {
      var data = JSON.parse(response.data);
      document.getElementById(
        "result"
      ).innerHTML = `${data["message"]} - FlottaID: ${data["flotta_id"]}`;
      document.getElementById("json").innerHTML = JSON.stringify(
        data.extra,
        null,
        4
      );
    })
    .catch(function (response) {
      document.getElementById("result").innerHTML = response;

      var data = JSON.parse(response.response.data);
      console.log(data.detail);

      document.getElementById("json").innerHTML = JSON.stringify(
        data.detail,
        null,
        4
      );
    });
};

function KalkulatorResult({ props }) {
  return (
    <div>
      <button
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-4"
        onClick={() => {
          getFlottaManagerPDF({ data: props });
        }}
      >
        PDF
      </button>
      <button
        className="bg-blue-200 hover:bg-blue-500 text-white font-bold py-2 px-4 rounded mr-4"
        onClick={() => {
          getFlottaManagerPDF({ data: props });
          uploadToMiniCRM({ data: props });
        }}
      >
        MiniCRM + PDF
      </button>
      <button
        className="bg-blue-200 hover:bg-blue-500 text-white font-bold py-2 px-4 rounded mr-4"
        onClick={() => {
          uploadToMiniCRM({ data: props });
        }}
      >
        MiniCRM
      </button>
      <div id="result" className="py-2"></div>
      {/* <div id="status" className="py-2"></div> */}
      <pre id="json" className="py-2"></pre>
    </div>
  );
}

export default KalkulatorResult;
