import "survey-core/defaultV2.min.css";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import * as SurveyCore from "survey-core";
import { surveyLocalization } from "survey-core";
import { FunctionFactory } from "survey-core";
import {
  // useCallback,
  useRef,
  useEffect,
  useState,
  // createContext,
  // useMemo,
} from "react";
import {
  // useRouteMatch,
  useParams,
} from "react-router-dom";

import Inputmask from "inputmask";
import { inputmask } from "surveyjs-widgets";
import { addYears, subDays, format } from "date-fns";

import Kalkulator from "./Kalkulator";
import KalkulatorResult from "./KalkulatorResult";
import LoadKalkulatorDataFromCRM from "./utils/LoadKalkulatorDataFromCRM";
import { date } from "yup";

import Loading from "./utils/Loading";

const DEBUG_MODE = false;

// surveyLocalization.defaultLocale = "hu";
// surveyLocalization.defaultLocale = "fr";

// const huLocale = SurveyCore.surveyLocalization.locales["hu"];
// console.log(huLocale);
// huLocale.pagePrevText = "Back";
// huLocale.pageNextText = "Forward";
// huLocale.completeText = "Send";
// huLocale.requiredText = "Send";

const mikroCegSeged = (bevetel, alkalmazottak, tevekenyseg, tulaj) => {
  bevetel = bevetel ? parseInt(bevetel) : 0;
  alkalmazottak = alkalmazottak ? parseInt(alkalmazottak) : 0;
  tevekenyseg = tevekenyseg ? true : false;
  tulaj = tulaj ? false : true;
  // console.log(">", bevetel, alkalmazottak, tevekenyseg, tulaj);
  return [bevetel, alkalmazottak, tevekenyseg, tulaj];
};

const mikroCheck = (bevetel, alkalmazottak, tevekenyseg, tulaj) => {
  var ret = parseInt(bevetel) >= 150 ? true : false;
  // console.log(">>>>>>>>1", ret);
  ret ||= parseInt(alkalmazottak) >= 10 ? true : false;
  // console.log(">>>>>>>>2", ret);
  ret ||= tevekenyseg;
  // console.log(">>>>>>>>3", ret);
  ret ||= tulaj;
  // console.log(">>>>>>>>4", ret);
  return ret;
};

function mikroCegE(params) {
  if (params) {
    var [bevetel, alkalmazottak, tevekenyseg, tulaj, flotta_tagok] = params;
    // console.log("*", flotta_tagok);
    var [bevetel, alkalmazottak, tevekenyseg, tulaj, flotta_tagok] =
      mikroCegSeged(bevetel, alkalmazottak, tevekenyseg, tulaj);
  }

  return !mikroCheck(bevetel, alkalmazottak, tevekenyseg, tulaj);
}

function nextMonthFirstDay(params) {
  var now = new Date();
  var current = new Date(now.getFullYear(), now.getMonth() + 1, 1);
  return current;
}

function endOfYearPeriod(params) {
  var now = new Date(params[0]);
  var ret = subDays(addYears(now, 1), 1);
  // console.log(now, ret)
  return format(ret, "yyyy-MM-dd");
}

function isSoonerThanToday(params) {
  var now = new Date();
  return now < new Date(params[0]);
}

function karStandard2(params) {
  // console.log(params);
  if (params[0] === "standard" && params[1] !== 2) {
    // console.log("false");
    return false;
  }
  return true;
}

function removeItemAll(arr, value) {
  var i = 0;
  while (i < arr.length) {
    if (arr[i] === value) {
      arr.splice(i, 1);
    } else {
      ++i;
    }
  }
  return arr;
}

// function addQuestion(survey, json) {
//   var page = survey.currentPage;
//   if (!page) return;

//   var question = page.addNewQuestion(json.type, json.name);
//   question.fromJSON(json);
//   page.addQuestion(question);
// }
// function addQuestions(survey) {
//   var json = {
//     type: "text",
//     name: "teszt",
//     readOnly: true,
//     defaultValue: "0",
//   };
//   // var q = survey.pages[0].addNewQuestion("checkbox");

//   addQuestion(survey, json);
// }

// kalkulal_kedvezmeny

FunctionFactory.Instance.register("mikroCegE", mikroCegE);
FunctionFactory.Instance.register("nextMonthFirstDay", nextMonthFirstDay);
FunctionFactory.Instance.register("endOfYearPeriod", endOfYearPeriod);
FunctionFactory.Instance.register("isSoonerThanToday", isSoonerThanToday);
FunctionFactory.Instance.register("karStandard2", karStandard2);

Inputmask.extendDefaults({
  greedy: false,
  clearMaskOnLostFocus: false,
  skipOptionalPartCharacter: " ",
});

inputmask(SurveyCore);

var storageName = "survey_antabo_kalkulator";
const surveyJson = {
  title: "Flotta kalkuláció",
  logoPosition: "right",
  showCompletedPage: false,
  previewText: {
    hu: "Tovább az áttekintésre",
  },
  completeText: {
    hu: "Befejezés",
  },
  editText: {
    hu: "Szerkesztés",
  },
  requiredText: "(*)",
  pages: [
    {
      name: "page1",
      elements: [
        {
          type: "text",
          name: "flotta_nev",
          title: {
            hu: "Flotta (Ügyfél-Kezelő) neve",
          },
          isRequired: true,
        },
        {
          type: "text",
          name: "flotta_email",
          title: {
            hu: "Flotta (Ügyfél-Kezelő) e-mail címe",
          },
          isRequired: true,
          visibleIf: "{kalkulator_tipusa} = 'uj'",
          validators: [
            {
              type: "regex",
              regex: "^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$",
              text: "Nem email cím formátum!",
            },
          ],
        },
        {
          type: "text",
          name: "flotta_telefon",
          startWithNewLine: false,
          title: {
            hu: "Flotta (Ügyfél-Kezelő) telefonszáma",
          },
          defaultValue: "+36",
          isRequired: true,
          inputType: "tel",
          placeholder: "+36 00 123 4567",
          autocomplete: "tel",
          visibleIf: "{kalkulator_tipusa} = 'uj'",
          validators: [
            {
              type: "regex",
              regex: "\\+36[0-9 ]{7,}$",
              text: "A telefonszámnak '+36'-val kell kezdődnie és legalább 7 karakternek utánna folytatnia. \nMegengedett karakterek: 0-9 és a szóköz",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "flotta_tipus",
          title: {
            hu: "Flotta (Ügyfél-Kezelő) típusa",
          },
          isRequired: true,
          choices: [
            {
              value: "magan",
              text: {
                hu: "magánszemély",
              },
            },
            {
              value: "egyeni",
              text: {
                hu: "egyéni vállalkozás",
              },
            },
            {
              value: "mikro_egyeb",
              text: {
                hu: "mikrocég vagy egyéb intézmény ",
              },
            },
            {
              value: "ceg",
              text: {
                hu: "cég",
              },
            },
          ],
          validators: [
            {
              type: "expression",
              text: {
                hu: "Mikrocég feltételeinek nem felel meg!",
              },
              expression:
                "{flotta_tipus} <> 'mikro_egyeb' or mikroCegE({arbevetel}, {alkamazott_szam}, {tevekenyseg}, {tulaj_aktiv_a_cegben}, {flotta_tagok}) or {flotta_vezetoi_engedely} contains 'igen'",
            },
          ],
        },
        {
          type: "dropdown",
          name: "flotta_csomag",
          startWithNewLine: false,
          title: {
            hu: "Választott Szolgáltatási szint – Biztosításmenedzser",
          },
          isRequired: true,
          defaultValue: "standard",
          choices: [
            {
              value: "passziv",
              text: {
                hu: "Passzív",
              },
            },
            {
              value: "standard",
              text: {
                hu: "Standard",
              },
              visibleIf: "{flotta_tipus} <> 'ceg'",
            },
            // {
            //   value: "all_in",
            //   text: {
            //     hu: "All In",
            //   },
            //   visibleIf: "{flotta_tipus} = 'magan'",
            // },
            {
              value: "egyedi",
              text: {
                hu: "Egyedi",
              },
            },
          ],
        },
        {
          type: "text",
          name: "flotta_szul_ido",
          visibleIf: "{flotta_tipus} anyof ['magan', 'egyeni']",
          title: {
            hu: "Ügyfél-Kezelő születési ideje",
          },
          isRequired: true,
          inputMask: "datetime",
          inputFormat: "yyyy-mm-dd",
        },
        {
          type: "text",
          name: "flotta_adoszam",
          visibleIf: "{flotta_tipus} anyof ['egyeni', 'mikro_egyeb', 'ceg']",
          title: {
            hu: "Ügyfél-Kezelő adószáma",
          },
          isRequired: true,
          inputFormat: "99999999-9-99",
        },
        {
          type: "panel",
          name: "flotta_ceg_adatok",
          visibleIf: "{flotta_tipus} anyof ['mikro_egyeb', 'ceg']",
          elements: [
            {
              type: "text",
              name: "arbevetel",
              title: "Árbevétel (MillioFt)",
              titleLocation: "left",
              isRequired: true,
              inputType: "number",
              validators: [
                {
                  type: "numeric",
                  minValue: 0,
                },
              ],
            },
            {
              type: "text",
              name: "alkamazott_szam",
              title: "Alkalmazottak száma (fő)",
              titleLocation: "left",
              inputType: "number",
              startWithNewLine: false,
              isRequired: true,

              validators: [
                {
                  type: "numeric",
                  minValue: 0,
                },
              ],
            },
            {
              type: "checkbox",
              name: "tevekenyseg",
              title: "Tevékenység",
              // titleLocation: "left",
              // isRequired: true,

              choices: [
                {
                  value: "item1",
                  text: {
                    hu: "Fuvarozó",
                  },
                },
                {
                  value: "item2",
                  text: {
                    hu: "Futár",
                  },
                },
                {
                  value: "item3",
                  text: {
                    hu: "Ipari gyártás",
                  },
                },
                {
                  value: "item4",
                  text: {
                    hu: "Nemzetközi tevékenység",
                  },
                },
                {
                  value: "item5",
                  text: {
                    hu: "Közéleti/Nyilvános tevékenység",
                  },
                },
              ],
            },
            {
              type: "checkbox",
              name: "tulaj_aktiv_a_cegben",
              title: "Tulajdonos aktívan a cégben dolgozik",
              titleLocation: "left",
              isRequired: false,
              startWithNewLine: false,
              choices: [
                {
                  value: "igen",
                  text: {
                    hu: "Igen",
                  },
                },
              ],
            },
          ],
          isRequired: true,
        },
        {
          type: "checkbox",
          name: "egyedi_szolgaltatasok",
          visibleIf: "{flotta_csomag} = 'egyedi'",
          title: {
            hu: "Egyedi  Szolgáltatások",
          },
          defaultValue: [
            "egyedi-item1",
            "egyedi-item2",
            "egyedi-item3",
            "egyedi-item4",
            "egyedi-item5",
            "egyedi-item6",
            "egyedi-item8",
            "egyedi-item9",
            "egyedi-item10",
            "egyedi-item11",
            "egyedi-item12",
            "egyedi-item13",
            "egyedi-item14",
          ],
          isRequired: true,
          choices: [
            {
              value: "egyedi-item1",
              text: {
                hu: "Teljeskörű képviselet",
              },
            },
            {
              value: "egyedi-item2",
              text: {
                hu: "Személyes jelenlét nélküli ügyintézés és biztosításkötés",
              },
            },
            {
              value: "egyedi-item3",
              text: {
                hu: "Díjmentes általános biztosítási ügyintézés",
              },
            },
            {
              value: "egyedi-item4",
              text: {
                hu: "Közvetlen kapcsolat kereskedéssel/hitelezővel",
              },
            },
            {
              value: "egyedi-item5",
              text: {
                hu: "Biztosítási virtuális asszisztencia",
              },
            },
            {
              value: "egyedi-item6",
              text: {
                hu: "Rendszeres díjfigyelés és elmaradásértesítés",
              },
            },
            {
              value: "egyedi-item8",
              text: {
                hu: "Kárbejelentés és tanácsadás – károsultként elszenvedett károk – kezelt biztosításokhoz kötődő fedezetek esetében (passzív károk)",
              },
            },
            {
              value: "egyedi-item9",
              text: {
                hu: "50%-os kedvezmény teljeskörű kárügyintézésre – kármenedzser",
              },
            },
            {
              value: "egyedi-item10",
              text: {
                hu: "Igazolások rendszeres kiadása",
              },
            },
            {
              value: "egyedi-item11",
              text: {
                hu: "Automatikus évfordulós felülvizsgálat – versenyeztetés",
              },
            },
            {
              value: "egyedi-item12",
              text: {
                hu: "Kedvezményes emelt szintű balesetbiztosítási opció az összes tagra a flottában",
              },
            },
            {
              value: "egyedi-item13",
              text: {
                hu: "Évente ___ óra/Korlátlan konzultációs és ajánlatkérési lehetőség – tanácsadás biztosítási védelemmel, kockázatkezeléssel kapcsolatban",
              },
            },
            {
              value: "egyedi-item14",
              text: {
                hu: "Irodán kívüli ügyintézés évente ___ alkalom ingyenes + további alkalmaknál is csak a kiszállási költségtérítést kell megfizetni, ügyintézési idő nem kerül felszámításra",
              },
            },
          ],
        },
        {
          type: "radiogroup",
          name: "flotta_konzultacio_korlatlan",
          visibleIf:
            "{egyedi_szolgaltatasok} contains 'egyedi-item13' and {flotta_csomag} = 'egyedi'",
          startWithNewLine: true,
          title: "Konzultációs és ajánlatkérési lehetőség korlátlan?",
          isRequired: true,
          choices: [
            {
              value: "igen",
              text: {
                hu: "Igen",
              },
            },
            {
              value: "nem",
              text: {
                hu: "Nem",
              },
            },
          ],
        },
        {
          type: "text",
          name: "flotta_konzultacio",
          visibleIf: "{flotta_konzultacio_korlatlan} = 'nem'",
          title: {
            hu: "Konzultációs és ajánlatkérési lehetőség - óra/évente",
          },
          isRequired: true,
          startWithNewLine: false,
          validators: [
            {
              type: "numeric",
              minValue: 1,
              text: {
                hu: "Legyen legalább 1",
              },
            },
          ],
        },
        {
          type: "radiogroup",
          name: "flotta_ugyintezes_korlatlan",
          visibleIf:
            "{egyedi_szolgaltatasok} contains 'egyedi-item14' and {flotta_csomag} = 'egyedi'",
          startWithNewLine: true,
          title: "Irodánkívüli ügyintézés korlátlan?",
          isRequired: true,
          choices: [
            {
              value: "igen",
              text: {
                hu: "Igen",
              },
            },
            {
              value: "nem",
              text: {
                hu: "Nem",
              },
            },
          ],
        },
        {
          type: "text",
          name: "flotta_ugyintezes",
          visibleIf: "{flotta_ugyintezes_korlatlan} = 'nem'",
          startWithNewLine: false,
          title: {
            hu: "Irodánkívüli ügyintézés - alkalom/évente",
          },
          isRequired: true,
          validators: [
            {
              type: "numeric",
              minValue: 1,
              text: {
                hu: "Legyen legalább 1",
              },
            },
          ],
        },
        {
          type: "multipletext",
          name: "egyeb_szolgaltatasok",
          visibleIf: "{flotta_csomag} = 'egyedi'",
          title: {
            hu: "Egyéb Szolgáltatások",
          },
          items: [
            {
              name: "Egyéb szolgáltatás (1)",
            },
            {
              name: "Egyéb szolgáltatás (2)",
            },
            {
              name: "Egyéb szolgáltatás (3)",
            },
          ],
        },
        {
          type: "comment",
          name: "egyedi_megallapodas",
          title: {
            hu: "Egyedi megállapodás – megjegyzés",
          },
          autoGrow: true,
        },
        {
          type: "checkbox",
          name: "kiegeszito_szolgaltatasok",
          title: {
            hu: "Kiegészítő szolgáltatások",
          },
          visibleIf: "{flotta_csomag} <> 'passziv'",
          choices: [
            {
              value: "kieg_kar",
              text: {
                hu: "Kármenedzser",
              },
            },
            {
              value: "teendo",
              text: {
                hu: "Teendő- és díjmenedzser",
              },
            },
            {
              value: "posta",
              text: {
                hu: "Postamenedzser",
              },
            },
            {
              value: "emelt_baleset_uk",
              text: {
                hu: "Emelt balesetbiztosítási csomag Ügyfél-Kezelőre",
              },
              visibleIf:
                "((currentYear() - getYear({flotta_szul_ido})) < 70) and ((currentYear() - getYear({flotta_szul_ido})) > 18)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "karugyintezes_korlatlan",
          visibleIf: "{kiegeszito_szolgaltatasok} contains 'kieg_kar'",
          title: {
            hu: "Kárügyintézés korlátlan?",
          },
          startWithNewLine: true,
          isRequired: true,
          choices: [
            {
              value: "igen",
              text: {
                hu: "Igen",
              },
            },
            {
              value: "nem",
              text: {
                hu: "Nem",
              },
            },
          ],
        },
        {
          type: "text",
          name: "karugyintezes",
          visibleIf: "{karugyintezes_korlatlan} = 'nem'",
          title: {
            hu: "Kárügyintézés - db/év",
          },
          isRequired: true,
          startWithNewLine: false,
          validators: [
            {
              type: "numeric",
              minValue: 1,
              text: {
                hu: "Legyen legalább 1",
              },
            },
            {
              type: "expression",
              text: {
                hu: "Standard csomag esetén korlátlan vagy 2 db/év választható.",
              },
              expression: "karStandard2({flotta_csomag},{karugyintezes})",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "ugyfel_kezelo_baleseti",
          visibleIf: "{kiegeszito_szolgaltatasok} contains 'emelt_baleset_uk'",
          title: {
            hu: "Emelt balesetbiztosítási csomag Ügyfél-Kezelőre",
          },
          isRequired: true,
          choices: [
            {
              value: "kiemelt",
              text: {
                hu: "Kiemelt",
              },
            },
            {
              value: "extra",
              text: {
                hu: "Extra",
              },
            },
            {
              value: "max",
              text: {
                hu: "Max",
              },
            },
            {
              value: "kiemelt_csaladi",
              text: {
                hu: "Kiemelt-Családi",
              },
            },
            {
              value: "extra_csaladi",
              text: {
                hu: "Extra-Családi",
              },
            },
            {
              value: "max_csaladi",
              text: {
                hu: "Max-Családi",
              },
            },
          ],
        },
        {
          type: "text",
          name: "flotta_szerzodes_szam",
          title: {
            hu: "Várható szerződések száma a teljes Flottában",
          },
          isRequired: true,
          validators: [
            {
              type: "numeric",
              minValue: 0,
            },
            {
              type: "expression",
              expression:
                "{flotta_szerzodes_szam} < 12 or {flotta_vezetoi_engedely} contains 'igen' or {flotta_csomag} == 'egyedi'",
              text: "12 db szerződés feletti kalkuláció csak egyedi Biztosításmenedzserrel vagy Vezetői engedéllyel lehet!",
            },
          ],
        },
        {
          type: "paneldynamic",
          name: "flotta_tagok",
          title: {
            hu: "Egyéb Flotta tagok bevonása",
          },
          visibleIf: "{flotta_csomag} <> 'all_in'",
          innerIndent: "3",
          templateElements: [
            {
              type: "text",
              name: "flotta_tag_contact_id",
              title: {
                hu: "flotta_tag_contact_id",
              },
              isRequired: false,
              startWithNewLine: true,
              readOnly: true,
              defaultValue: "0",
            },
            {
              type: "text",
              name: "flotta_tag_id",
              title: {
                hu: "flotta_tag_id",
              },
              isRequired: false,
              startWithNewLine: false,
              readOnly: true,
              defaultValue: "0",
            },
            {
              type: "text",
              name: "flotta_tag_baleseti_id",
              title: {
                hu: "flotta_tag_baleseti_id",
              },
              isRequired: false,
              startWithNewLine: false,
              readOnly: true,
              defaultValue: "0",
            },
            {
              type: "text",
              name: "flotta_tag_nev",
              title: {
                hu: "Név",
              },
              isRequired: true,
              startWithNewLine: true,
            },
            {
              type: "dropdown",
              name: "flotta_tag_tipus",
              startWithNewLine: false,
              title: {
                hu: "Típusa",
              },
              isRequired: true,
              choicesFromQuestion: "flotta_tipus",
            },
            {
              type: "text",
              name: "flotta_tag_szul_ido",
              startWithNewLine: true,
              visibleIf: "{panel.flotta_tag_tipus} anyof ['magan', 'egyeni']",
              title: {
                hu: "Születési idő",
              },
              isRequired: true,
              inputMask: "datetime",
              inputFormat: "yyyy-mm-dd",
            },
            {
              type: "dropdown",
              name: "flotta_tag_csomag",
              visibleIf:
                "((currentYear() - getYear({panel.flotta_tag_szul_ido})) < 70) and ((currentYear() - getYear({panel.flotta_tag_szul_ido})) > 18)",
              startWithNewLine: false,
              title: {
                hu: "Emelt balesetbiztosítási csomag",
              },
              isRequired: true,
              choices: [
                {
                  value: "nem",
                  text: {
                    hu: "Nem igényli.",
                  },
                },
                {
                  value: "kiemelt",
                  text: {
                    hu: "Kiemelt",
                  },
                },
                {
                  value: "extra",
                  text: {
                    hu: "Extra",
                  },
                },
                {
                  value: "max",
                  text: {
                    hu: "Max",
                  },
                },
                {
                  value: "kiemelt_csaladi",
                  text: {
                    hu: "Kiemelt-Családi",
                  },
                },
                {
                  value: "extra_csaladi",
                  text: {
                    hu: "Extra-Családi",
                  },
                },
                {
                  value: "max_csaladi",
                  text: {
                    hu: "Max-Családi",
                  },
                },
              ],
            },
            {
              type: "text",
              name: "flotta_tag_adoszam",
              visibleIf:
                "{panel.flotta_tag_tipus} anyof ['egyeni', 'mikro_egyeb', 'ceg']",
              title: {
                hu: "Adószáma",
              },
              isRequired: true,
              inputFormat: "99999999-9-99",
            },
            {
              type: "panel",
              name: "flotta_ceg_adatok",
              visibleIf:
                "{panel.flotta_tag_tipusAAAA} anyof ['mikro_egyeb', 'ceg']",
              elements: [
                {
                  type: "text",
                  name: "arbevetel",
                  title: "Árbevétel (MillioFt)",
                  titleLocation: "left",
                  isRequired: true,
                  inputType: "number",
                  validators: [
                    {
                      type: "numeric",
                      minValue: 0,
                    },
                  ],
                },
                {
                  type: "text",
                  name: "alkamazott_szam",
                  title: "Alkalmazottak száma (fő)",
                  titleLocation: "left",
                  startWithNewLine: false,
                  inputType: "number",
                  isRequired: true,
                  validators: [
                    {
                      type: "numeric",
                      minValue: 0,
                    },
                  ],
                },
                {
                  type: "checkbox",
                  name: "tevekenyseg",
                  title: "Tevékenység",
                  // titleLocation: "left",
                  // isRequired: true,
                  choices: [
                    {
                      value: "item1",
                      text: {
                        hu: "Fuvarozó",
                      },
                    },
                    {
                      value: "item2",
                      text: {
                        hu: "Futár",
                      },
                    },
                    {
                      value: "item3",
                      text: {
                        hu: "Ipari gyártás",
                      },
                    },
                    {
                      value: "item4",
                      text: {
                        hu: "Nemzetközi tevékenység",
                      },
                    },
                    {
                      value: "item5",
                      text: {
                        hu: "Közéleti/Nyilvános tevékenység",
                      },
                    },
                  ],
                },
                {
                  type: "checkbox",
                  name: "tulaj_aktiv_a_cegben",
                  startWithNewLine: false,
                  title: "Tulajdonos aktívan a cégben dolgozik",
                  titleLocation: "left",
                  isRequired: false,
                  choices: [
                    {
                      value: "igen",
                      text: {
                        hu: "Igen",
                      },
                    },
                  ],
                },
              ],
              isRequired: true,
            },
          ],
          panelsState: "collapsed",
          confirmDelete: true,
          panelAddText: {
            hu: "Új tag hozzáadása",
          },
          panelRemoveText: {
            hu: "Tag eltávolítása",
          },
        },
        {
          type: "radiogroup",
          name: "flotta_kedvezmeny_tipus",
          title: {
            hu: "Díjkorrekció típusa",
          },
          isRequired: true,
          defaultValue: "nincs",
          choices: [
            {
              value: "nincs",
              text: {
                hu: "nincs",
              },
            },
            {
              value: "-",
              text: {
                hu: "+/-",
              },
            },
            {
              value: "-%",
              text: {
                hu: "+/- %",
              },
            },
          ],
        },
        {
          type: "text",
          name: "flotta_kedvezmeny_mertek",
          title: {
            hu: "Díjkorrekció mértéke",
          },
          description: {
            hu: "A -% típussal, a tizedesvessző karakter: . (pont) pl.: 12.5",
          },
          isRequired: true,
          defaultValue: "0",
          visibleIf:
            "{flotta_kedvezmeny_tipus} <> 'nincs' and {flotta_kedvezmeny_tipus} notempty",
          validators: [
            {
              type: "expression",
              text: {
                hu: "Nem lehet 0.",
              },
              expression: "{flotta_kedvezmeny_mertek} <> 0",
            },
          ],
        },
        {
          type: "text",
          name: "flotta_egyedi_ar",
          title: {
            hu: "Flotta egyedi ár",
          },
          isRequired: true,
          defaultValue: "0",
          visibleIf: "{flotta_csomag} = 'egyedi'",
          validators: [
            {
              type: "numeric",
              minValue: 0,
            },
          ],
        },
        {
          type: "checkbox",
          name: "flotta_vezetoi_engedely",
          title: {
            hu: "Vezetői engedély",
          },
          isRequired: false,
          choices: [
            {
              value: "igen",
              text: {
                hu: "Vezetői engedélly OK",
              },
            },
          ],
        },
        {
          type: "checkbox",
          name: "flotta_akciok",
          title: {
            hu: "Akciók",
          },
          visibleIf: "{flotta_csomag} = 'standard'",
          isRequired: false,
          choices: [
            {
              value: "Lojalitás",
              text: {
                hu: "Lojalitás kedvezmény  -25%",
              },
              visibleIf: false,
            },
            {
              value: "Hűség",
              text: {
                hu: "Hűség kedvezmény -35%",
              },
              visibleIf: false,
            },
          ],
        },
        {
          type: "text",
          name: "flotta_kupon",
          title: {
            hu: "Flotta kupon",
          },
          isRequired: true,
          readOnly: true,
          enableIf: false,
          defaultValue: "0",
        },
        {
          type: "text",
          name: "flotta_id",
          title: {
            hu: "Flotta ID",
          },
          isRequired: false,
          readOnly: true,
          enableIf: false,
          defaultValue: "0",
        },
        {
          type: "text",
          name: "flotta_manager_id",
          title: {
            hu: "Flotta Manager Adatlap ID",
          },
          isRequired: false,
          startWithNewLine: false,
          readOnly: true,
          enableIf: false,
          defaultValue: "0",
        },
        {
          type: "text",
          name: "ugyfel_kezelo_contact_id",
          title: {
            hu: "Ügyfél kezelő Személy ID",
          },
          isRequired: false,
          startWithNewLine: true,
          readOnly: true,
          enableIf: false,
          visibleIf: "true",
          defaultValue: "0",
          includeinResult: true,
        },
        {
          type: "text",
          name: "ugyfel_kezelo_id",
          title: {
            hu: "Ügyfél kezelő ID",
          },
          isRequired: false,
          startWithNewLine: false,
          readOnly: true,
          enableIf: false,
          visibleIf: "true",
          defaultValue: "0",
        },
        {
          type: "text",
          name: "ugyfel_kezelo_baleseti_id",
          title: {
            hu: "Ügyfél kezelő Baleseti ID",
          },
          isRequired: false,
          startWithNewLine: false,
          readOnly: true,
          enableIf: false,
          visibleIf: true,
          defaultValue: "0",
        },
        {
          type: "radiogroup",
          name: "kalkulator_tipusa",
          title: {
            hu: "Kalkulátor típusa",
          },
          isRequired: false,
          readOnly: true,
          enableIf: false,
          visibleIf: DEBUG_MODE,
          defaultValue: "uj",
          choices: [
            {
              value: "uj",
              text: {
                hu: "uj",
              },
            },
            {
              value: "megujitas",
              text: {
                hu: "megujitas",
              },
            },
            {
              value: "modositas",
              text: {
                hu: "modositas",
              },
            },
          ],
        },
        {
          type: "text",
          name: "kalkulator_kitoltes_datuma",
          startWithNewLine: true,
          title: {
            hu: "Kalkulátor kitöltés dátuma",
          },
          isRequired: true,
          inputType: "date",
          readOnly: true,
          defaultValue: "=today()",
        },
        {
          type: "text",
          name: "flotta_idoszak_kezdete",
          startWithNewLine: true,
          title: {
            hu: "Flotta időszak kezdete",
          },
          isRequired: true,
          defaultValue: "=nextMonthFirstDay()",
          inputMask: "datetime",
          inputFormat: "yyyy-mm-dd",
          validators: [
            {
              type: "expression",
              expression:
                "{kalkulator_kitoltes_datuma} <= {flotta_idoszak_kezdete}",
              text: "Kitöltés nem lehet a kezdet előtt.",
            },
          ],
        },
        {
          type: "expression",
          name: "flotta_idoszak_vege",
          startWithNewLine: false,
          title: {
            hu: "Flotta időszak vége",
          },
          // displayStyle: "date",
          inputMask: "datetime",
          inputFormat: "yyyy-mm-dd",
          expression: "endOfYearPeriod({flotta_idoszak_kezdete})",
        },
      ],
      calculatedValues: [
        {
          name: "asdasd",
          expression: "{flotta_telefon}",
          includeIntoResult: true,
        },
      ],
    },
  ],
  showTitle: false,
  autoGrowComment: true,
  widthMode: "responsive",
};

function KenyelmiKalkulatorForm() {
  const { flottaId } = useParams();
  const isLoading = useRef(true);

  const survey = new Model(surveyJson);

  // survey.requiredError = "asd";
  survey.locale = "hu";

  console.log("0", survey.locale);
  console.log("1", survey.defaultLocale);
  console.log("2", survey.completeText);
  // console.log(surveyLocalization.locales["en"].requiredError);

  const [surveyData, setSurveyData] = useState(survey.data);
  const [lastQuestion, setLastQuestion] = useState("flotta_nev");
  const [surveyState, setsurveyState] = useState("");

  var prevData = window.localStorage.getItem(storageName) || null;
  useEffect(() => {
    if (prevData && !flottaId) {
      console.log("Load data from cookie.");
      var data = JSON.parse(prevData);
      setSurveyData(data);
    }
    if (flottaId) {
      const getData = async () => {
        console.log("Loading flotta data (" + flottaId + ") from API...");
        try {
          const data = await LoadKalkulatorDataFromCRM(flottaId);
          const map = new Map(Object.entries(data));
          var obj = {};
          for (const item of map) {
            obj[item[0]] = item[1];
          }
          isLoading.current = false;
          setSurveyData({
            ...surveyData,
            ...obj,
          });
          survey.data = surveyData;
        } catch (err) {
          console.log(err);
        } finally {
          console.log("Finished loading");
        }
        console.log("Loaded flotta data (" + flottaId + ") from API.");
      };
      getData();
    }
  }, []);

  survey.data = surveyData;
  survey.clearInvisibleValues = "onHidden";
  survey.checkErrorsMode = "onValueChanged";
  survey.showPreviewBeforeComplete = "showAllQuestions";
  if (surveyState === "completed") {
    survey.doComplete();
  }

  survey.validate();

  survey.getQuestionByName(lastQuestion).focus();

  survey.onValueChanged.add((sender, options) => {
    addExtraSurveyData(sender);
    saveSurveyData(survey.data);
    setLastQuestion(options.name);
    setsurveyState(survey.state);
    // console.log(">>>>", survey.data);
    // console.log(">>>>", survey.data["asdasd"]);
  });

  survey.onComplete.add((sender, options) => {
    console.log("complete");
    setsurveyState(survey.state);
  });

  function saveSurveyData(data) {
    window.localStorage.setItem(storageName, JSON.stringify(data));
    // console.log("Survey saved.");
  }

  function addExtraSurveyData(sender) {
    let result_props = {
      values: { ...sender.data },
      values_only: "true",
    };

    // let kieg = sender.data["kiegeszito_szolgaltatasok"] || [];
    // if (sender.data["flotta_csomag"] == "egyedi") {
    //   if (sender.data["egyedi_szolgaltatasok"].includes("egyedi-item9")) {
    //     if (!kieg.includes("kieg_kar")) {
    //       kieg.push("kieg_kar");
    //     }
    //   } else {
    //     removeItemAll(kieg, "kieg_kar");
    //   }
    //   if (sender.data["egyedi_szolgaltatasok"].includes("egyedi-item6")) {
    //     if (!kieg.includes("teendo")) {
    //       kieg.push("teendo");
    //     }
    //   } else {
    //     removeItemAll(kieg, "teendo");
    //   }
    // }

    setSurveyData({
      ...sender.data,
      // kiegeszito_szolgaltatasok: kieg,
      flotta_dij: Kalkulator(result_props)[0],
      flotta_ossz_kedvezmeny: Kalkulator(result_props)[1],
      kitoltes_ideje: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
    });
  }
  function deleteSurveyData() {
    console.log("Survey deleted.");
    survey.clear();
    // setSurveyData(survey.data);
    window.localStorage.removeItem(storageName);
  }

  // console.log(flottaId, isLoading.current);

  if (flottaId && isLoading.current) {
    return <Loading />;
  }

  // survey.setVariable("asdasd", new Date().getFullYear());

  return (
    <>
      {prevData && !flottaId && (
        <button
          className="bg-gray-200 text-dark-grey font-bold py-2 px-4 rounded mb-8"
          onClick={() => deleteSurveyData()}
        >
          Új kalkuláció
        </button>
      )}
      {surveyState === "completed" && <KalkulatorResult props={surveyData} />}
      <div className="grid grid-cols-5">
        <div className="col-span-4">
          <Survey model={survey} />
        </div>
        <div className="col-span-1">
          <Kalkulator values={surveyData} />
        </div>
      </div>
    </>
  );
}

export default KenyelmiKalkulatorForm;
