import KenyelmiKalkulatorForm from "./KenyelmiKalkulatorForm";

function KenyelmiKalkulator() {
  // const isLogin = true;

  return (
    <div className="flex flex-row w-full">
      <div className="py-12 flex-1">
        {/* <div className="flex bg-white rounded-lg shadow-2xl overflow-hidden mx-auto max-w-sm lg:max-w-4xl"> */}
        <div className="flex bg-white rounded-lg shadow-2xl">
          <div className="w-full p-0 max-w">
            <h2 className="text-2xl font-semibold text-gray-600 text-center">
              ANTABO Flotta Kalkulátor
            </h2>
            <div className="mt-4 flex items-center justify-between"></div>
            <KenyelmiKalkulatorForm />
          </div>
        </div>
      </div>
    </div>
  );
}

export default KenyelmiKalkulator;
