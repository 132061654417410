import React, { useEffect, useState } from "react";

import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { auth, logout } from "./firebase";

import NavBar from "./components/NavBar";
import KenyelmiKalkulator from "./components/KenyelmiKalkulator";

function Dashboard() {
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (loading) return;
    if (!user) {
      return navigate("/?destination=" + location.pathname);
    }
  }, [user, loading, navigate]);

  return (
    <div className="w-screen">
      {/* <NavBar /> */}
      <div className="">
        <div>
          Bejelentkezve, mint {user?.email}
          <span> </span>
          <button
            className="bg-rose-500 text-white active:bg-rose-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            onClick={logout}
          >
            Kijelentkezés
          </button>
        </div>
      </div>

      <KenyelmiKalkulator />
    </div>
  );
}

export default Dashboard;
